import { DownloadOutline } from "heroicons-react"
import React from "react"
import ContactForm from "../components/ContactForm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { ContentHeading, ContentSubHeading } from "../components/styles"
import report from "../assets/2021 Black Friday insights Pricelist.pdf"
import reportCover from "../assets/reportCover.png"

export default function BFCM() {
  return (
    <>
      <Layout>
        <>
          <SEO title={"Black Friday and Cyber Monday Insights | Pricelist"} />
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-lg">
              <ContentSubHeading>Smart displays market</ContentSubHeading>
              <h1>
                <ContentHeading>
                  Black Friday and Cyber Monday 2021 Market Insights
                </ContentHeading>
                <div className="grid gap-16 grid-cols-2 my-16">
                  <div>
                    <a href={report}>
                      <img
                        src={reportCover}
                        className="rounded shadow transform"
                      />
                    </a>
                  </div>
                  <div className="flex items-center">
                    <div className="flex px-2 py-4 text-left text-red-50 hover:text-white font-semibold leading-relaxed bg-red-700 rounded-lg hover:shadow-xl transform hover:scale-105 space-x-4 duration-300">
                      <DownloadOutline className="w-12 h-12" />
                      <a href={report}>
                        Download the Black Friday and Cyber Monday Report
                      </a>
                    </div>
                  </div>
                </div>
                <div className="my-8 w-full h-px border-t"></div>
                <ContentHeading>...or contact us to learn more</ContentHeading>
                <ContactForm cta="Contact Us" />
              </h1>
            </div>
          </div>
        </>
      </Layout>
    </>
  )
}
